import React from "react";
import { QrReader } from "react-qr-reader";

export default class QRScan extends React.Component {
    state = {
        delay: 1000,
        result: '',
        constraints: {facingMode:'environment'}
    };

    handleScan = (result) => {
        let context = this;
        context.setState({ result: result });

        this.props.updateData(result)
    };

    ViewFinder = () => {
        return (
            <div className="scanFinder">

            </div>
        );
    }

   render() {
        return (
            <div>
                <QrReader
                    scanDelay={this.state.delay}
                    constraints={this.state.constraints}
                    onResult={(result, error) => {
                        if (!!result) {
                            this.handleScan(result?.text);
                        }
                    }}
                    className='scanBlock'
                    ViewFinder={ this.ViewFinder }
                />
            </div>
        );
    }
}

import React, {Component} from 'react';
import QRScan from "./components/Camera";

import Logo from "./assets/img/logo.svg";
import "./App.css";

export default class App extends Component {
    state = {
        scanned: true,
        geolocation: false,
        scanData: '',
        coords: {latitude: '', longitude: ''},
        documentUrl: '',
        noty: 'Наведіть камеру на QR код',
    };

    getCurrentPosition = () => {
        let context = this;

        navigator.geolocation.getCurrentPosition(function (position) {
            context.setState({geolocation: true});

            context.setState(prevState => {
                let coords = Object.assign({}, prevState.coords);
                coords.latitude = position.coords.latitude;
                return {coords};
            })

            context.setState(prevState => {
                let coords = Object.assign({}, prevState.coords);
                coords.longitude = position.coords.longitude;
                return {coords};
            })
        });
    }

    updateScanData = (value) => {
        let context = this,
            code = context.state.scanData,
            lat = context.state.coords.latitude,
            lon = context.state.coords.longitude;

        this.setState({scanData: value})

        const requestUrl = 'https://test.aidmonitor.org/api/app/scan-qr-code';
        const postBody = {code, lat, lon};

        const requestMetadata = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(postBody)
        };

        fetch(requestUrl, requestMetadata)
            .then(res => res.json())
            .then(recipes => {
                if (recipes.success) {
                    this.setState({documentUrl: recipes.document});
                    this.setState({scanned: false})
                }
            });
    }

    oneMoreScan = () => {
        this.setState({scanned: true})
        this.setState({documentUrl: false})
    }

    render() {
        const { geolocation } = this.state;
        const { documentUrl } = this.state;
        const { scanned } = this.state;

        const qrReader = (
            <div className={scanned ? 'qr-reader' : 'qr-reader _hide'}>
                <QRScan updateData={this.updateScanData}/>
                <div className="noty">{this.state.noty}</div>
            </div>
        );

        const acceptGeo = (
            <div className="button bold _m-auto" onClick={this.getCurrentPosition}>
                Для сканування QR code увімкніть GPS
            </div>
        );

        const downloadBtn = (
            <div className="_flex _flex-column">
                <a href={this.state.documentUrl} className="button button--download bold" target="_blank" rel="noreferrer" download>
                    Завантажити документ
                </a>

                <div className="button bold" onClick={this.oneMoreScan}>
                    Скануваты знову
                </div>
            </div>
        );

        return (
            <div className="App">
                <header>
                    <img src={Logo} alt="Logo"/>
                </header>

                {geolocation ? qrReader : acceptGeo}

                {documentUrl ? downloadBtn : ''}
            </div>
        );
    }
}

